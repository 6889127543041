@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); }
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

@font-face {
  font-family: 'GothamPro-Regular';
  src: url("../fonts/GothamPro.otf"); }

@font-face {
  font-family: 'GothamPro-Bold';
  src: url("../fonts/GothamPro-Bold.otf"); }

@font-face {
  font-family: 'Panton-Black';
  src: url("../fonts/Panton-Black.otf"); }

@font-face {
  font-family: 'Panton-Bold';
  src: url("../fonts/Panton-Bold.otf"); }

@font-face {
  font-family: 'Panton-ExtraBold';
  src: url("../fonts/Panton-ExtraBold.ttf"); }

@font-face {
  font-family: 'Panton-Light';
  src: url("../fonts/Panton-Light.otf"); }

@font-face {
  font-family: 'Panton-Regular';
  src: url("../fonts/Panton-Regular.otf"); }

@font-face {
  font-family: 'Panton-SemiBold';
  src: url("../fonts/Panton-SemiBold.ttf"); }
