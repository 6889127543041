input:focus, textarea:focus, button:focus, textarea:focus, a:focus {
  outline: 0; }
a:hover {
  text-decoration: none; }
p,h1,h2,h3,h4,h5,h6,ul,ol,li {
  margin: 0;
  padding: 0;
  list-style: none; }
body {
  font-family: 'GothamPro-Regular'; }
.threeHouses {
  padding-bottom: 50px;
  margin: auto;
  position: relative;
  z-index: 10;
  &-ul {
    display: flex;
    justify-content: space-between; }

  &-li {
    position: relative;
    width: 100%;
    @media(max-width: 767px) {
      margin-bottom: 20px; }
    ul {
      padding-top: 30px;
      width: 250px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      display: none;
      &:after {
        display: block;
        position: absolute;
        top: 23px;
        left: 0;
        right: 0;
        margin: auto;
        background: #f9f9eb;
        content: "";
        width: 15px;
        height: 15px;
        transform: rotate(45deg); }
      li {
        border-bottom: 1px solid #dbdac9;
        overflow: hidden;
        background: #f9f9eb;
        &:last-of-type {
          border: none;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px; }
        &:first-of-type {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px; }
        a {
          font-size: 14px;
          color: #010101;
          font-family: 'GothamPro-Bold';
          padding: 11px 20px;
          display: block;
          text-align: center;
          &:hover {
            color: #8bcb2e;
            background: #ffffff; } } } }
    &:hover {
      ul {
        display: block;
        z-index: 1000; } } }
  &-a {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    img {}
    span {
      font-size: 18px;
      color: #dbd8ac;
      font-family: 'GothamPro-Bold';
      transition-duration: 0.4s; }
    &:hover {
      span {
        color: #8bcb2e; } } } }

.owl-nav {
  button {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto !important;
    height: 35px;
    span {
      font-size: 11px;
      font-family: 'GothamPro-Bold';
      color: #e48e56;
      transition-duration: 0.4s; }
    img {}
    &:hover {
      background: none !important;
      span {
        color: #8bcb2e; } }
    &.owl-prev {
      align-items: flex-end;
      left: 0; }
    &.owl-next {
      right: 0; } } }
.owl-dots {
  .owl-dot {
    span {
      background: #e3e3e3 !important;
      margin: 3px !important; }
    &.active {
      span {
        background: #8ccd2f !important; } } } }
