.questions {
  background: url(../img/contactsBG.png) center no-repeat;
  overflow: hidden;
  @media(max-width: 991px) {
    background: none; }
  .col-lg-5:nth-of-type(1) {
    @media(max-width: 991px) {
      &:after {
        display: block;
        position: absolute;
        left: -100%;
        right: -100%;
        background: #424235;
        top: 0;
        bottom: 0;
        content: ""; } } }
  .col-lg-5:nth-of-type(2) {
    @media(max-width: 991px) {
      &:after {
        display: block;
        position: absolute;
        left: -100%;
        right: -100%;
        background: #8ccd2f;
        top: 0;
        bottom: 0;
        content: ""; } } }
  &-element {
    padding-top: 80px;
    padding-bottom: 50px;
    text-align: center;
    max-width: 400px;
    margin: auto;
    position: relative;
    z-index: 1;
    @media(max-width: 991px) {
      padding-top: 50px; }
    .text1 {
      color: #ecebc9;
      font-family: 'Panton-ExtraBold';
      text-transform: uppercase;
      margin-bottom: 20px; }
    .text2 {
      font-size: 18px;
      font-family: 'Panton-ExtraBold';
      color: white;
      margin-bottom: 35px; }
    .noPay {
      color: #8bcb2e;
      font-family: 'Panton-ExtraBold';
      text-transform: uppercase;
      font-size: 26px;
      margin-bottom: 15px; }
    form {
      @media(max-width: 991px) {
        padding-bottom: 30px; }
      .input-container {
        display: flex;
        margin-bottom: 25px;
        input {
          height: 60px;
          width: 50%;
          padding-left: 30px;
          font-size: 13px;
          border: none;
          @media(max-width: 575px) {
            padding-left: 18px; } }
        input:nth-of-type(1) {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
          margin-right: 1px; }
        input:nth-of-type(2) {
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px; } }
      .form-button {
        max-width: 210px;
        width: 100%;
        background: transparent;
        height: 60px;
        border: 2px solid black;
        color: white;
        border-radius: 100px;
        font-size: 12px;
        color: black;
        font-family: 'Panton-ExtraBold';
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;
        transition-duration: 0.4s;
        cursor: pointer;
        &:hover {
          color: #8bcb2e;
          background: black; } } } }
  &-button1 {
    max-width: 260px;
    height: 60px;
    border: 2px solid #8bcb2e;
    color: white;
    border-radius: 100px;
    font-size: 12px;
    color: #ffffff;
    font-family: 'Panton-ExtraBold';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    transition-duration: 0.4s;
    &:hover {
      color: black;
      background: #8bcb2e; } } }
.footer {
  padding-bottom: 30px;
  background: #f9f9eb;
  position: relative;
  &:after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 160px;
    height: 160px;
    border-radius: 160px;
    content: "";
    background: #f9f9eb;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto; }
  .container {
    z-index: 1;
    position: relative; }
  .logo {
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    z-index: 2; }

  &-phones {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 15px;
    @media(max-width: 575px) {
      flex-direction: column;
      padding-top: 80px; } }
  &-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 991px) {
      flex-direction: column; }
    @media(max-width: 575px) {
      padding-top: 20px; }
    .greyText {
      font-size: 13px;
      color: #b9b7a0; }
    .line {
      width: 20px;
      height: 1px;
      background: #8ccd2f;
      margin-left: 40px;
      margin-right: 40px;
      @media(max-width: 1199px) {
        margin-left: 20px;
        margin-right: 20px; }
      @media(max-width: 991px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 3px; } }
    .phone {
      font-size: 17px;
      color: #000000;
      font-family: 'GothamPro-Bold'; }
    &:nth-of-type(1) {}
    &:nth-of-type(2) {
      flex-direction: row-reverse;
      @media(max-width: 991px) {
        flex-direction: column; } } }
  .copyright {
    color: #b9b7a0;
    font-size: 11px;
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1px;
      margin: auto;
      content: "";
      background: #c5c6b8;
      right: 0;
      left: 0;
      z-index: 0; }
    &:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1px;
      margin: auto;
      content: "";
      left: 0;
      right: 0;
      height: 20px;
      background: #f9f9eb;
      max-width: 160px;
      z-index: 1; }
    span {
      position: relative;
      z-index: 1; } }
  &-links {
    max-width: 670px;
    display: flex;
    justify-content: space-between;
    font-family: 'GothamPro-Bold';
    margin: 0 auto 35px;
    @media(max-width: 575px) {
      flex-direction: column;
      align-items: center; }
    a {
      color: black; } }
  .users {
    font-size: 12px;
    color: #b7b5a1;
    margin-bottom: 20px;
    display: flex;
    justify-content: center; }
  .users-img {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    @media(max-width: 575px) {
      display: none; } }
  .vkButton {
    font-family: 'GothamPro-Bold';
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 45px;
    border-radius: 100px;
    color: white;
    background: #8ccd2f;
    margin: 0 auto 30px;
    i {
      font-size: 13px;
      margin-right: 15px; } }
  .createSite {
    display: flex;
    justify-content: center; } }
.modal {
  &-dialog {
    max-width: 665px;
    margin: 50px auto 0;
    padding-left: 15px;
    padding-right: 0px; }
  &-content {
    background: #f9f9eb;
    position: relative;
    border: none;
    &:after {
      display: block;
      width: 150px;
      height: 150px;
      background: #f9f9eb;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -30px;
      border-radius: 1000px;
      z-index: -1; }
    .close {
      opacity: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: -20px;
      text-align: center;
      z-index: 1;
      display: block;
      width: 25px;
      margin: auto;
      span {} } }
  &-body {
    text-align: center;
    padding: 0;
    padding-top: 40px; }
  &-title {
    font-size: 26px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    margin-bottom: 20px; }
  .ok {
    position: relative;
    width: 100%;
    z-index: 2;
    margin-bottom: 30px;
    &:before {
      display: block;
      left: 0;
      right: 0;
      height: 1px;
      content: "";
      top: 0;
      bottom: 0;
      margin: auto;
      background: #dbdac9;
      position: absolute;
      z-index: 0; }
    &:after {
      display: block;
      left: 0;
      right: 0;
      height: 20px;
      width: 35px;
      content: "";
      top: 0;
      bottom: 0;
      margin: auto;
      background: #f9f9eb;
      position: absolute;
      z-index: 0; }
    i {
      position: relative;
      z-index: 1;
      color: #8bcb2e;
      font-size: 14px; } }
  form {
    max-width: 600px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    .input-container {
      display: flex;
      margin-bottom: 18px;
      @media(max-width: 520px) {
        flex-direction: column;
        margin-bottom: 0; }
      input {
        width: 33.33333333%;
        padding-left: 30px;
        height: 60px;
        font-size: 13px;
        border: 1px solid #dbdac9;
        @media(max-width: 520px) {
          margin-bottom: 18px;
          width: 100%;
          border-radius: 100px; }
        &:focus {
          border-color: #8bcb2e; }
        &:nth-of-type(1) {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px; }
        &:nth-of-type(2) {}
        &:nth-of-type(3) {
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px; } } }
    textarea {
      height: 100px;
      width: 100%;
      border-radius: 15px;
      padding-left: 30px;
      padding-top: 20px;
      font-size: 13px;
      border: 1px solid #dbdac9;
      resize: none;
      margin-bottom: 15px;
      &:focus {
        border-color: #8bcb2e; } }
    .share {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      label:nth-of-type(1) {
        cursor: pointer; }
      label:nth-of-type(2) {
        cursor: pointer;
        font-size: 13px;
        color: #b8b8b7; }
      input {
        display: none; } }
    button {
      height: 60px;
      width: 210px;
      border-radius: 100px;
      border: none;
      color: white;
      background: #8bcb2e;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      cursor: pointer;
      transition-duration: 0.4s;
      &:hover {
        color: #8bcb2e;
        background: white; } } }
  &-footer {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    background: url(../img/modalBG.png) center top no-repeat;
    padding-top: 75px;
    padding-bottom: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @media(max-width: 520px) {
      background-size: cover; } } }
