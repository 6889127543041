.menu {
  margin-top: 60px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  @media(max-width: 1199px) {
    background: #f9f9eb;
    margin-top: 0; }
  .container {
    position: relative;
    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      background: url(../img/navLine.png) center no-repeat;
      background-size: auto 100%;
      @media(max-width: 1199px) {
        display: none; } } }

  &-header {
    margin-bottom: -60px;
    @media(max-width: 1199px) {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 7px;
      padding-bottom: 7px; } }
  &-logo {
    padding-top: 10px;
    margin: auto;
    max-width: 117px;
    position: relative;
    z-index: 101;
    @media(max-width: 1199px) {
      margin: 0; }
    &-computer {
      @media(max-width: 1199px) {
        display: none; }
      img {} }
    &-mobile {
      display: none;
      @media(max-width: 1199px) {
        display: block; }
      a:nth-of-type(1) {
        img {} }
      a:nth-of-type(2) {
        @media(max-width: 1199px) {
          display: none; }
        i {
          color: black; } } } }
  &-phone {
    display: none;
    @media(max-width: 1199px) {
      display: block;
      color: black;
      font-family: 'GothamPro-Bold';
      font-size: 14px;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      &:hover {
        color: black; } }
    @media(max-width: 500px) {
      display: none; } }
  &-toggle {
    height: 25px;
    display: none;
    @media(max-width: 1199px) {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    span {
      width: 40px;
      height: 4px;
      background: black; } }
  &-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -15px;
    z-index: 100;
    @media(max-width: 1199px) {
      top: 0;
      flex-direction: column;
      padding-bottom: 15px;
      padding-top: 15px;
      display: none;
      &.open {
        display: flex; } } }
  &-menu {
    display: flex;
    max-width: 460px;
    justify-content: space-between;
    width: 100%;
    @media(max-width: 1199px) {
      flex-direction: column;
      max-width: none;
      margin-bottom: 30px; }
    &-li {
      @media(max-width: 1199px) {
        margin-bottom: 10px; }
      ul {
        padding-top: 23px;
        width: 220px;
        position: absolute;
        left: 0;
        margin: auto;
        display: none;
        @media(max-width: 1199px) {
          position: static; }
        li {
          border-bottom: 1px solid #dbdac9;
          overflow: hidden;
          background: #f9f9eb;
          &:last-of-type {
            border: none;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px; }
          &:first-of-type {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px; }
          a {
            font-size: 14px;
            color: #010101;
            font-family: 'GothamPro-Bold';
            padding: 11px 20px;
            display: block;
            text-align: left;
            @media(max-width: 1199px) {
              text-align: center; }
            &:hover {
              color: #8bcb2e;
              background: #ffffff; } } } }
      &:hover {
        ul {
          display: block; } } }
    &-a {
      font-size: 10px;
      color: #e5edbe;
      font-family: 'GothamPro-Bold';
      position: relative;
      display: block;
      @media(max-width: 1199px) {
        text-align: center;
        font-size: 16px;
        color: black; }
      &:after {
        display: none;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        content: "";
        background: #8ccd2f;
        position: absolute;
        bottom: -26px; }
      &:hover, &.active {
        color: #8ccd2f;
        &:after {
          display: block;
          @media(max-width: 1199px) {
            display: none; } } } } }
  &-contacts {
    display: flex;
    align-items: center;
    max-width: 460px;
    width: 100%;
    justify-content: space-between;
    @media(max-width: 1199px) {
      justify-content: center;
      max-width: none; }
    .phone {
      color: #e5edbe;
      font-family: 'GothamPro-Bold';
      font-size: 14px;
      @media(max-width: 1199px) {
        display: none; } } }
  &-buttons {
    display: flex;
    @media(max-width: 1199px) {
      flex-direction: column; }
    a {
      color: #8ccd2f;
      font-size: 12px;
      font-family: 'GothamPro-Bold';
      border: 1px solid #8ccd2f;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      i {
        color: #ef9052;
        margin-right: 10px; }
      &:hover {
        background: #8ccd2f;
        color: black;
        i {
          color: black; } } }
    a:nth-of-type(1) {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      @media(max-width: 1199px) {
        border-radius: 0; } }
    a:nth-of-type(2) {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      @media(max-width: 1199px) {
        border-radius: 0; } } } }

.menu.scroll {
  background: #f9f9eb;
  margin-top: 0px;
  .container {}
  .menu-header {
    margin-bottom: -50px;
    position: relative;
    top: 10px;
    @media(max-width: 1199px) {
      margin-bottom: 0;
      top: 0; }
    &:after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 170px;
      background: #f9f9eb;
      border-radius: 100px;
      content: "";
      max-width: 170px;
      margin: auto;
      bottom: -10px;
      @media(max-width: 1199px) {
        display: none; } } }
  .menu-logo {
    &-computer {
      display: none;
      img {} }
    &-mobile {
      display: block;
      text-align: center;
      a:nth-of-type(1) {
        img {} }
      a:nth-of-type(2) {
        display: inline-block;
        margin-top: 10px;
        @media(max-width: 1199px) {
          display: none; }
        i {} } } }
  .menu-phone {}
  .menu-toggle {
    span {} }
  .menu-body {}
  .menu-menu {
    &-li {
      ul {
        li {
          a {} } } }
    &-a {
      color: black;
      &:hover, &.active {
        color: #8ccd2f;
        &:after {
          display: none; } } } }
  .menu-contacts {
    .phone {
      color: black; } }
  .menu-buttons {
    i {}
    a:nth-of-type(1) {}
    a:nth-of-type(2) {} } }
