.hero {
  background: url(../img/heroBG.png) center no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: -175px;
  @media(max-width: 991px) {
    margin-bottom: 0; }
  .owl-carousel {
    margin: 0 auto 100px;
    @media(max-width: 991px) {
      margin-bottom: 0px; }
    .item {
      background: url(../img/heroBG.png) center no-repeat;
      background-size: cover;
      padding-top: 290px;
      padding-bottom: 300px;
      @media(max-width: 767px) {
        padding-top: 100px;
        padding-bottom: 730px; } } }
  .owl-nav {
    position: absolute;
    max-width: 830px;
    left: 15px;
    right: 15px;
    top: 340px;
    margin: auto;
    @media(max-width: 767px) {
      top: 160px; }
    @media(max-width: 575px) {
      top: 310px; }
    button {
      margin: 0 !important;
      &.owl-prev {}
      &.owl-next {} } }
  .owl-dots {
    display: none;
    .owl-dot {
      span {} } }
  &-element {
    max-width: 420px;
    margin: auto;
    @media(max-width: 767px) {
      max-width: 300px; }
    .section-title {
      font-size: 28px;
      color: #dbd8ac;
      font-family: 'Panton-ExtraBold';
      text-align: center;
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 5px;
      @media(max-width: 575px) {
        font-size: 20px; }
      &:after {
        display: block;
        width: 45px;
        height: 1px;
        background: #8bcb2e;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto; } }
    .days {
      color: #56a90e;
      font-size: 40px;
      font-family: 'Panton-ExtraBold';
      text-align: center;
      margin-bottom: 25px;
      @media(max-width: 575px) {
        font-size: 25px; } }
    .buttonDetails {
      height: 65px;
      width: 220px;
      background: #8bcb2e;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      border-radius: 100px;
      margin: auto;
      transition-duration: 0.4s;
      &:hover {
        background: black;
        color: #8bcb2e; } } }
  .threeHouses {
    max-width: 730px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    &-ul {
      @media(max-width: 767px) {
        flex-direction: column; } } } }
.whyUs {
  padding: 130px 0 100px;
  background: url(../img/whyUsBG.png) no-repeat center top;
  position: relative;
  z-index: 1;
  @media(max-width: 991px) {
    background: #54a60e;
    padding-top: 60px;
    padding-bottom: 50px; }
  &:after {
    display: block;
    width: 153px;
    height: 53px;
    content: "";
    bottom: 30px;
    left: 0;
    right: 0;
    position: absolute;
    background: url(../img/award.png) center no-repeat;
    margin: auto;
    @media(max-width: 991px) {
      bottom: -20px; } }
  .section-title {
    font-size: 26px;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    color: black;
    margin-bottom: 30px; }
  .elements {
    display: flex;
    justify-content: space-between;
    @media(max-width: 991px) {
      flex-wrap: wrap;
      justify-content: space-around; } }
  &-element {
    text-align: center;
    width: 20%;
    transition-duration: 0.4s;
    &:hover {
      transform: translateY(-20px); }
    @media(max-width: 991px) {
      width: 33.33333333%;
      margin-bottom: 20px; }
    @media(max-width: 767px) {
      width: 50%; }
    @media(max-width: 575px) {
      width: 100%; }
    img {
      margin-bottom: 15px; }
    p {
      text-transform: uppercase;
      color: #ffffff;
      font-family: 'Panton-ExtraBold';
      font-size: 14px; }
    span {
      color: black;
      font-size: 11px;
      font-family: 'Panton-ExtraBold';
      display: inline-block;
      position: relative;
      &:after {
        display: block;
        width: 10px;
        height: 1px;
        top: -2px;
        bottom: 0;
        margin: auto;
        left: -15px;
        content: "";
        background: black;
        position: absolute; }
      &:before {
        display: block;
        width: 10px;
        height: 1px;
        top: -2px;
        bottom: 0;
        margin: auto;
        right: -15px;
        content: "";
        background: black;
        position: absolute; } } } }
.aboutUs {
  text-align: center;
  padding-bottom: 50px;
  @media(max-width: 991px) {
    padding-top: 50px; }
  .text1 {
    font-size: 18px;
    font-family: 'GothamPro-Bold';
    max-width: 700px;
    margin: 0 auto 40px;
    padding-bottom: 25px;
    position: relative;
    &:after {
      display: block;
      height: 1px;
      width: 110px;
      background: black;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute; } }
  .text2 {
    font-size: 14px;
    color: #6f6f6f;
    max-width: 820px;
    margin: 0 auto 20px; }
  .more {
    font-size: 12px;
    color: #8ccd2f;
    font-family: 'Panton-ExtraBold';
    text-transform: uppercase;
    border-bottom: 1px solid; } }
.works {
  overflow: hidden;
  padding-top: 60px;
  background: #f9f9eb;
  position: relative;
  @media(max-width: 767px) {
    padding-bottom: 50px; }
  &:after {
    display: block;
    width: 665px;
    height: 665px;
    border-radius: 1000px;
    background: white;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -570px;
    @media(max-width: 767px) {
      display: none; } }
  .owl-carousel {
    margin-bottom: 100px;
    @media(max-width: 767px) {
      margin-bottom: 50px; }
    .owl-item {
      opacity: 0.5;
      transition-duration: 0.4s;
      &.active {
        opacity: 1; } } }
  .owl-stage-outer {
    overflow: visible; }
  .owl-nav {
    position: absolute;
    width: 100%;
    max-width: 360px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    @media(max-width: 575px) {
      position: static; }
    button {
      margin: 0 !important;
      top: -94px;
      @media(max-width: 575px) {
        top: auto;
        bottom: -40px; }
      &.owl-prev {}
      &.owl-next {} } }
  .owl-dots {
    display: none;
    .owl-dot {
      span {} } }
  .section-title {
    font-size: 20px;
    text-align: center;
    font-family: 'Panton-ExtraBold';
    margin-bottom: 60px;
    position: relative;
    span {
      position: relative;
      z-index: 5; }
    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      height: 1px;
      background: #dbdac9;
      content: "";
      z-index: 0; }
    &:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      background: #f9f9eb;
      content: "";
      width: 200px;
      z-index: 1; } }
  .work {
    img {
      transition-duration: 0.4s; }
    .area {
      width: 60px;
      height: 60px;
      border-radius: 100px;
      background: #f9f9eb;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      text-align: center;
      margin: -20px auto 0;
      z-index: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-of-type(1) {
        display: block;
        color: black;
        border-bottom: 1px solid;
        max-width: 35px; }
      span:nth-of-type(2) {
        display: block;
        color: #8ccd2f; } }
    .name {
      color: black;
      text-align: center;
      font-family: 'Panton-ExtraBold'; }
    &:hover {
      img {
        filter: brightness(50%); } } }
  .allWorks {
    width: 225px;
    height: 65px;
    background: #c3835b;
    border-radius: 100px;
    color: white;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition-duration: 0.4s;
    z-index: 1;
    position: relative;
    &:hover {
      color: #c3835b;
      background: white; } } }

.stocks {
  padding-top: 85px;
  padding-bottom: 60px;
  .owl-nav {
    max-width: 300px;
    position: relative;
    margin: 60px auto 0;
    button {
      &.owl-prev {}
      &.owl-next {} } }
  .owl-dots {
    .owl-dot {
      span {} } }
  .section-title {
    font-size: 26px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    border-bottom: 1px solid #dbdac9;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 50px;
    &:after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 1px;
      width: 100%;
      max-width: 315px;
      background: #8ccd2f;
      margin: auto; } }
  .item {
    border-radius: 5px;
    height: 190px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;
    color: #403b37;
    text-transform: uppercase;
    font-family: 'Panton-Bold';
    &.stock1 {
      background: url(../img/stockBG1.png) center no-repeat;
      background-size: cover; }
    &.stock2 {
      background: url(../img/stockBG2.png) center no-repeat;
      background-size: cover; } } }
.whyUs2 {
  background: url(../img/whyUs2BG.png) center no-repeat;
  background-size: cover;

  .container {
    padding-top: 220px;
    padding-bottom: 220px;
    position: relative;
    @media(max-width: 991px) {
      padding-top: 50px;
      padding-bottom: 50px; } }
  &-center {
    width: 379px;
    height: 379px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
    @media(max-width: 991px) {
      margin-bottom: 20px; }
    @media(max-width: 575px) {
      width: auto;
      height: auto;
      background: none; }
    &:after {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: "";
      background: url(../img/whyUs2Check.png) center no-repeat;
      background-size: 100% 100%;
      animation: pulse 3s infinite; }
    p {
      font-family: 'Panton-ExtraBold';
      font-size: 60px;
      text-align: center;
      color: white;
      margin-bottom: 40px;
      position: relative;
      z-index: 1;
      span {
        display: block;
        font-size: 26px;
        line-height: 0px; } }
    a {
      width: 220px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: #8ccd2f;
      color: black;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      transition-duration: 0.4s;
      position: relative;
      z-index: 1;
      &:hover {
        background: black;
        color: #8ccd2f; } } }
  .elements {
    @media(max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; } }
  &-element {
    max-width: 260px;
    text-align: center;
    position: absolute;
    height: 200px;
    @media(max-width: 991px) {
      position: static;
      margin: 0 !important;
      height: auto;
      margin-bottom: 20px !important; }
    img {}
    .title {
      color: #c8e943;
      font-size: 15px;
      font-family: 'Panton-ExtraBold';
      text-transform: uppercase;
      margin-bottom: 10px; }
    .text {
      font-size: 14px;
      color: white; }
    &:nth-of-type(1) {
      left: 0;
      right: 0;
      margin: auto;
      top: 10px; }
    &:nth-of-type(2) {
      right: 10%;
      top: 70px; }
    &:nth-of-type(3) {
      right: 1%;
      top: 0;
      bottom: 0;
      margin: auto; }
    &:nth-of-type(4) {
      right: 10%;
      bottom: 70px; }
    &:nth-of-type(5) {
      right: 0;
      left: 0;
      bottom: 60px;
      margin: auto; }
    &:nth-of-type(6) {
      left: 10%;
      bottom: 70px; }
    &:nth-of-type(7) {
      left: 1%;
      top: 0;
      bottom: 0;
      margin: auto; }
    &:nth-of-type(8) {
      left: 10%;
      top: 70px; } } }
.feedbacks {
  padding-top: 65px;
  padding-bottom: 45px;
  position: relative;
  .container {
    position: relative; }
  .owl-nav {
    @media(max-width: 767px) {
      display: none; }
    button {
      &.owl-prev {}
      &.owl-next {} } }
  .owl-dots {
    .owl-dot {
      span {} } }
  .owl-carousel {
    max-width: 840px;
    margin: 0 auto 30px; }
  .section-title {
    font-size: 26px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    position: relative;
    margin-bottom: 55px; }
  &-number {
    text-align: center;
    background: white;
    width: 65px;
    margin: 0 auto 40px;
    color: #838383;
    font-family: 'Panton-Light';
    &:after {
      display: block;
      position: absolute;
      max-width: 440px;
      height: 1px;
      margin: auto;
      left: 0;
      right: 0;
      content: "";
      background: #8ccd2f;
      top: 97px;
      z-index: -1; }
    &:before {
      display: block;
      position: absolute;
      height: 1px;
      margin: auto;
      left: 0;
      right: 0;
      content: "";
      background: #dbdac9;
      top: 97px;
      z-index: -1; } }
  .feedback {
    margin-bottom: 30px;
    text-align: center;
    &-img {
      width: 100%;
      max-width: 373px;
      margin: 0 auto -14px; }
    &-icon {
      width: 100%;
      max-width: 53px;
      margin: 0 auto 10px; }
    &-name {
      display: inline-block;
      position: relative;
      padding-left: 70px;
      padding-right: 70px;
      font-family: 'Panton-ExtraBold';
      color: black;
      margin-bottom: 15px;
      &:after {
        display: block;
        position: absolute;
        left: 0;
        width: 50px;
        height: 1px;
        bottom: 0;
        top: 0;
        margin: auto;
        content: "";
        background: #d5d5d5; }
      &:before {
        display: block;
        position: absolute;
        right: 0;
        width: 50px;
        height: 1px;
        bottom: 0;
        top: 0;
        margin: auto;
        content: "";
        background: #d5d5d5; } }
    &-type {
      font-size: 13px;
      color: #8ccd2f;
      font-family: 'GothamPro-Bold';
      margin-bottom: 10px; }
    &-text {
      font-size: 14px;
      color: #6f6f6f; } }
  .allFeedbacks {
    width: 220px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    font-family: 'Panton-ExtraBold';
    background: #c3835b;
    border-radius: 100px;
    margin: auto;
    transition-duration: 0.4s;
    &:hover {
      background: white;
      color: #c3835b; } } }



@keyframes pulse {
  0% {
    transform: scale(0.9); }

  50% {
    transform: scale(1);
    opacity: 1; }

  100% {
    transform: scale(0.9); } }


