@import url(../../bower_components/bootstrap/dist/css/bootstrap.css);
@import url(../../bower_components/font-awesome/web-fonts-with-css/css/fontawesome-all.css);
@import url(../../bower_components/owl.carousel/dist/assets/owl.carousel.css);
@import url(../../bower_components/owl.carousel/dist/assets/owl.theme.default.css);
/* Slider */
@import url(../../bower_components/fancybox/dist/jquery.fancybox.min.css);
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

@font-face {
  font-family: 'GothamPro-Regular';
  src: url("../fonts/GothamPro.otf"); }

@font-face {
  font-family: 'GothamPro-Bold';
  src: url("../fonts/GothamPro-Bold.otf"); }

@font-face {
  font-family: 'Panton-Black';
  src: url("../fonts/Panton-Black.otf"); }

@font-face {
  font-family: 'Panton-Bold';
  src: url("../fonts/Panton-Bold.otf"); }

@font-face {
  font-family: 'Panton-ExtraBold';
  src: url("../fonts/Panton-ExtraBold.ttf"); }

@font-face {
  font-family: 'Panton-Light';
  src: url("../fonts/Panton-Light.otf"); }

@font-face {
  font-family: 'Panton-Regular';
  src: url("../fonts/Panton-Regular.otf"); }

@font-face {
  font-family: 'Panton-SemiBold';
  src: url("../fonts/Panton-SemiBold.ttf"); }

input:focus, textarea:focus, button:focus, textarea:focus, a:focus {
  outline: 0; }

a:hover {
  text-decoration: none; }

p, h1, h2, h3, h4, h5, h6, ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none; }

body {
  font-family: 'GothamPro-Regular'; }

.threeHouses {
  padding-bottom: 50px;
  margin: auto;
  position: relative;
  z-index: 10; }
  .threeHouses-ul {
    display: flex;
    justify-content: space-between; }
  .threeHouses-li {
    position: relative;
    width: 100%; }
    @media (max-width: 767px) {
      .threeHouses-li {
        margin-bottom: 20px; } }
    .threeHouses-li ul {
      padding-top: 30px;
      width: 250px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      display: none; }
      .threeHouses-li ul:after {
        display: block;
        position: absolute;
        top: 23px;
        left: 0;
        right: 0;
        margin: auto;
        background: #f9f9eb;
        content: "";
        width: 15px;
        height: 15px;
        transform: rotate(45deg); }
      .threeHouses-li ul li {
        border-bottom: 1px solid #dbdac9;
        overflow: hidden;
        background: #f9f9eb; }
        .threeHouses-li ul li:last-of-type {
          border: none;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px; }
        .threeHouses-li ul li:first-of-type {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px; }
        .threeHouses-li ul li a {
          font-size: 14px;
          color: #010101;
          font-family: 'GothamPro-Bold';
          padding: 11px 20px;
          display: block;
          text-align: center; }
          .threeHouses-li ul li a:hover {
            color: #8bcb2e;
            background: #ffffff; }
    .threeHouses-li:hover ul {
      display: block;
      z-index: 1000; }
  .threeHouses-a {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; }
    .threeHouses-a span {
      font-size: 18px;
      color: #dbd8ac;
      font-family: 'GothamPro-Bold';
      transition-duration: 0.4s; }
    .threeHouses-a:hover span {
      color: #8bcb2e; }

.owl-nav button {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto !important;
  height: 35px; }
  .owl-nav button span {
    font-size: 11px;
    font-family: 'GothamPro-Bold';
    color: #e48e56;
    transition-duration: 0.4s; }
  .owl-nav button:hover {
    background: none !important; }
    .owl-nav button:hover span {
      color: #8bcb2e; }
  .owl-nav button.owl-prev {
    align-items: flex-end;
    left: 0; }
  .owl-nav button.owl-next {
    right: 0; }

.owl-dots .owl-dot span {
  background: #e3e3e3 !important;
  margin: 3px !important; }

.owl-dots .owl-dot.active span {
  background: #8ccd2f !important; }

.menu {
  margin-top: 60px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100; }
  @media (max-width: 1199px) {
    .menu {
      background: #f9f9eb;
      margin-top: 0; } }
  .menu .container {
    position: relative; }
    .menu .container:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      background: url(../img/navLine.png) center no-repeat;
      background-size: auto 100%; }
      @media (max-width: 1199px) {
        .menu .container:after {
          display: none; } }
  .menu-header {
    margin-bottom: -60px; }
    @media (max-width: 1199px) {
      .menu-header {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 7px;
        padding-bottom: 7px; } }
  .menu-logo {
    padding-top: 10px;
    margin: auto;
    max-width: 117px;
    position: relative;
    z-index: 101; }
    @media (max-width: 1199px) {
      .menu-logo {
        margin: 0; } }
    @media (max-width: 1199px) {
      .menu-logo-computer {
        display: none; } }
    .menu-logo-mobile {
      display: none; }
      @media (max-width: 1199px) {
        .menu-logo-mobile {
          display: block; } }
      @media (max-width: 1199px) {
        .menu-logo-mobile a:nth-of-type(2) {
          display: none; } }
      .menu-logo-mobile a:nth-of-type(2) i {
        color: black; }
  .menu-phone {
    display: none; }
    @media (max-width: 1199px) {
      .menu-phone {
        display: block;
        color: black;
        font-family: 'GothamPro-Bold';
        font-size: 14px;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center; }
        .menu-phone:hover {
          color: black; } }
    @media (max-width: 500px) {
      .menu-phone {
        display: none; } }
  .menu-toggle {
    height: 25px;
    display: none; }
    @media (max-width: 1199px) {
      .menu-toggle {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
    .menu-toggle span {
      width: 40px;
      height: 4px;
      background: black; }
  .menu-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -15px;
    z-index: 100; }
    @media (max-width: 1199px) {
      .menu-body {
        top: 0;
        flex-direction: column;
        padding-bottom: 15px;
        padding-top: 15px;
        display: none; }
        .menu-body.open {
          display: flex; } }
  .menu-menu {
    display: flex;
    max-width: 460px;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 1199px) {
      .menu-menu {
        flex-direction: column;
        max-width: none;
        margin-bottom: 30px; } }
    @media (max-width: 1199px) {
      .menu-menu-li {
        margin-bottom: 10px; } }
    .menu-menu-li ul {
      padding-top: 23px;
      width: 220px;
      position: absolute;
      left: 0;
      margin: auto;
      display: none; }
      @media (max-width: 1199px) {
        .menu-menu-li ul {
          position: static; } }
      .menu-menu-li ul li {
        border-bottom: 1px solid #dbdac9;
        overflow: hidden;
        background: #f9f9eb; }
        .menu-menu-li ul li:last-of-type {
          border: none;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px; }
        .menu-menu-li ul li:first-of-type {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px; }
        .menu-menu-li ul li a {
          font-size: 14px;
          color: #010101;
          font-family: 'GothamPro-Bold';
          padding: 11px 20px;
          display: block;
          text-align: left; }
          @media (max-width: 1199px) {
            .menu-menu-li ul li a {
              text-align: center; } }
          .menu-menu-li ul li a:hover {
            color: #8bcb2e;
            background: #ffffff; }
    .menu-menu-li:hover ul {
      display: block; }
    .menu-menu-a {
      font-size: 10px;
      color: #e5edbe;
      font-family: 'GothamPro-Bold';
      position: relative;
      display: block; }
      @media (max-width: 1199px) {
        .menu-menu-a {
          text-align: center;
          font-size: 16px;
          color: black; } }
      .menu-menu-a:after {
        display: none;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        content: "";
        background: #8ccd2f;
        position: absolute;
        bottom: -26px; }
      .menu-menu-a:hover, .menu-menu-a.active {
        color: #8ccd2f; }
        .menu-menu-a:hover:after, .menu-menu-a.active:after {
          display: block; }
          @media (max-width: 1199px) {
            .menu-menu-a:hover:after, .menu-menu-a.active:after {
              display: none; } }
  .menu-contacts {
    display: flex;
    align-items: center;
    max-width: 460px;
    width: 100%;
    justify-content: space-between; }
    @media (max-width: 1199px) {
      .menu-contacts {
        justify-content: center;
        max-width: none; } }
    .menu-contacts .phone {
      color: #e5edbe;
      font-family: 'GothamPro-Bold';
      font-size: 14px; }
      @media (max-width: 1199px) {
        .menu-contacts .phone {
          display: none; } }
  .menu-buttons {
    display: flex; }
    @media (max-width: 1199px) {
      .menu-buttons {
        flex-direction: column; } }
    .menu-buttons a {
      color: #8ccd2f;
      font-size: 12px;
      font-family: 'GothamPro-Bold';
      border: 1px solid #8ccd2f;
      display: flex;
      align-items: center;
      padding: 10px 15px; }
      .menu-buttons a i {
        color: #ef9052;
        margin-right: 10px; }
      .menu-buttons a:hover {
        background: #8ccd2f;
        color: black; }
        .menu-buttons a:hover i {
          color: black; }
    .menu-buttons a:nth-of-type(1) {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px; }
      @media (max-width: 1199px) {
        .menu-buttons a:nth-of-type(1) {
          border-radius: 0; } }
    .menu-buttons a:nth-of-type(2) {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px; }
      @media (max-width: 1199px) {
        .menu-buttons a:nth-of-type(2) {
          border-radius: 0; } }

.menu.scroll {
  background: #f9f9eb;
  margin-top: 0px; }
  .menu.scroll .menu-header {
    margin-bottom: -50px;
    position: relative;
    top: 10px; }
    @media (max-width: 1199px) {
      .menu.scroll .menu-header {
        margin-bottom: 0;
        top: 0; } }
    .menu.scroll .menu-header:after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 170px;
      background: #f9f9eb;
      border-radius: 100px;
      content: "";
      max-width: 170px;
      margin: auto;
      bottom: -10px; }
      @media (max-width: 1199px) {
        .menu.scroll .menu-header:after {
          display: none; } }
  .menu.scroll .menu-logo-computer {
    display: none; }
  .menu.scroll .menu-logo-mobile {
    display: block;
    text-align: center; }
    .menu.scroll .menu-logo-mobile a:nth-of-type(2) {
      display: inline-block;
      margin-top: 10px; }
      @media (max-width: 1199px) {
        .menu.scroll .menu-logo-mobile a:nth-of-type(2) {
          display: none; } }
  .menu.scroll .menu-menu-a {
    color: black; }
    .menu.scroll .menu-menu-a:hover, .menu.scroll .menu-menu-a.active {
      color: #8ccd2f; }
      .menu.scroll .menu-menu-a:hover:after, .menu.scroll .menu-menu-a.active:after {
        display: none; }
  .menu.scroll .menu-contacts .phone {
    color: black; }

.questions {
  background: url(../img/contactsBG.png) center no-repeat;
  overflow: hidden; }
  @media (max-width: 991px) {
    .questions {
      background: none; } }
  @media (max-width: 991px) {
    .questions .col-lg-5:nth-of-type(1):after {
      display: block;
      position: absolute;
      left: -100%;
      right: -100%;
      background: #424235;
      top: 0;
      bottom: 0;
      content: ""; } }
  @media (max-width: 991px) {
    .questions .col-lg-5:nth-of-type(2):after {
      display: block;
      position: absolute;
      left: -100%;
      right: -100%;
      background: #8ccd2f;
      top: 0;
      bottom: 0;
      content: ""; } }
  .questions-element {
    padding-top: 80px;
    padding-bottom: 50px;
    text-align: center;
    max-width: 400px;
    margin: auto;
    position: relative;
    z-index: 1; }
    @media (max-width: 991px) {
      .questions-element {
        padding-top: 50px; } }
    .questions-element .text1 {
      color: #ecebc9;
      font-family: 'Panton-ExtraBold';
      text-transform: uppercase;
      margin-bottom: 20px; }
    .questions-element .text2 {
      font-size: 18px;
      font-family: 'Panton-ExtraBold';
      color: white;
      margin-bottom: 35px; }
    .questions-element .noPay {
      color: #8bcb2e;
      font-family: 'Panton-ExtraBold';
      text-transform: uppercase;
      font-size: 26px;
      margin-bottom: 15px; }
    @media (max-width: 991px) {
      .questions-element form {
        padding-bottom: 30px; } }
    .questions-element form .input-container {
      display: flex;
      margin-bottom: 25px; }
      .questions-element form .input-container input {
        height: 60px;
        width: 50%;
        padding-left: 30px;
        font-size: 13px;
        border: none; }
        @media (max-width: 575px) {
          .questions-element form .input-container input {
            padding-left: 18px; } }
      .questions-element form .input-container input:nth-of-type(1) {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-right: 1px; }
      .questions-element form .input-container input:nth-of-type(2) {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px; }
    .questions-element form .form-button {
      max-width: 210px;
      width: 100%;
      background: transparent;
      height: 60px;
      border: 2px solid black;
      color: white;
      border-radius: 100px;
      font-size: 12px;
      color: black;
      font-family: 'Panton-ExtraBold';
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: auto;
      transition-duration: 0.4s;
      cursor: pointer; }
      .questions-element form .form-button:hover {
        color: #8bcb2e;
        background: black; }
  .questions-button1 {
    max-width: 260px;
    height: 60px;
    border: 2px solid #8bcb2e;
    color: white;
    border-radius: 100px;
    font-size: 12px;
    color: #ffffff;
    font-family: 'Panton-ExtraBold';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    transition-duration: 0.4s; }
    .questions-button1:hover {
      color: black;
      background: #8bcb2e; }

.footer {
  padding-bottom: 30px;
  background: #f9f9eb;
  position: relative; }
  .footer:after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 160px;
    height: 160px;
    border-radius: 160px;
    content: "";
    background: #f9f9eb;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto; }
  .footer .container {
    z-index: 1;
    position: relative; }
  .footer .logo {
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    z-index: 2; }
  .footer-phones {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 15px; }
    @media (max-width: 575px) {
      .footer-phones {
        flex-direction: column;
        padding-top: 80px; } }
  .footer-phone {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991px) {
      .footer-phone {
        flex-direction: column; } }
    @media (max-width: 575px) {
      .footer-phone {
        padding-top: 20px; } }
    .footer-phone .greyText {
      font-size: 13px;
      color: #b9b7a0; }
    .footer-phone .line {
      width: 20px;
      height: 1px;
      background: #8ccd2f;
      margin-left: 40px;
      margin-right: 40px; }
      @media (max-width: 1199px) {
        .footer-phone .line {
          margin-left: 20px;
          margin-right: 20px; } }
      @media (max-width: 991px) {
        .footer-phone .line {
          margin-left: 0px;
          margin-right: 0px;
          margin-top: 3px; } }
    .footer-phone .phone {
      font-size: 17px;
      color: #000000;
      font-family: 'GothamPro-Bold'; }
    .footer-phone:nth-of-type(2) {
      flex-direction: row-reverse; }
      @media (max-width: 991px) {
        .footer-phone:nth-of-type(2) {
          flex-direction: column; } }
  .footer .copyright {
    color: #b9b7a0;
    font-size: 11px;
    text-align: center;
    position: relative;
    margin-bottom: 25px; }
    .footer .copyright:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1px;
      margin: auto;
      content: "";
      background: #c5c6b8;
      right: 0;
      left: 0;
      z-index: 0; }
    .footer .copyright:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1px;
      margin: auto;
      content: "";
      left: 0;
      right: 0;
      height: 20px;
      background: #f9f9eb;
      max-width: 160px;
      z-index: 1; }
    .footer .copyright span {
      position: relative;
      z-index: 1; }
  .footer-links {
    max-width: 670px;
    display: flex;
    justify-content: space-between;
    font-family: 'GothamPro-Bold';
    margin: 0 auto 35px; }
    @media (max-width: 575px) {
      .footer-links {
        flex-direction: column;
        align-items: center; } }
    .footer-links a {
      color: black; }
  .footer .users {
    font-size: 12px;
    color: #b7b5a1;
    margin-bottom: 20px;
    display: flex;
    justify-content: center; }
  .footer .users-img {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center; }
    @media (max-width: 575px) {
      .footer .users-img {
        display: none; } }
  .footer .vkButton {
    font-family: 'GothamPro-Bold';
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 45px;
    border-radius: 100px;
    color: white;
    background: #8ccd2f;
    margin: 0 auto 30px; }
    .footer .vkButton i {
      font-size: 13px;
      margin-right: 15px; }
  .footer .createSite {
    display: flex;
    justify-content: center; }

.modal-dialog {
  max-width: 665px;
  margin: 50px auto 0;
  padding-left: 15px;
  padding-right: 0px; }

.modal-content {
  background: #f9f9eb;
  position: relative;
  border: none; }
  .modal-content:after {
    display: block;
    width: 150px;
    height: 150px;
    background: #f9f9eb;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -30px;
    border-radius: 1000px;
    z-index: -1; }
  .modal-content .close {
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    text-align: center;
    z-index: 1;
    display: block;
    width: 25px;
    margin: auto; }

.modal-body {
  text-align: center;
  padding: 0;
  padding-top: 40px; }

.modal-title {
  font-size: 26px;
  color: black;
  font-family: 'Panton-ExtraBold';
  text-align: center;
  margin-bottom: 20px; }

.modal .ok {
  position: relative;
  width: 100%;
  z-index: 2;
  margin-bottom: 30px; }
  .modal .ok:before {
    display: block;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    top: 0;
    bottom: 0;
    margin: auto;
    background: #dbdac9;
    position: absolute;
    z-index: 0; }
  .modal .ok:after {
    display: block;
    left: 0;
    right: 0;
    height: 20px;
    width: 35px;
    content: "";
    top: 0;
    bottom: 0;
    margin: auto;
    background: #f9f9eb;
    position: absolute;
    z-index: 0; }
  .modal .ok i {
    position: relative;
    z-index: 1;
    color: #8bcb2e;
    font-size: 14px; }

.modal form {
  max-width: 600px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .modal form .input-container {
    display: flex;
    margin-bottom: 18px; }
    @media (max-width: 520px) {
      .modal form .input-container {
        flex-direction: column;
        margin-bottom: 0; } }
    .modal form .input-container input {
      width: 33.33333333%;
      padding-left: 30px;
      height: 60px;
      font-size: 13px;
      border: 1px solid #dbdac9; }
      @media (max-width: 520px) {
        .modal form .input-container input {
          margin-bottom: 18px;
          width: 100%;
          border-radius: 100px; } }
      .modal form .input-container input:focus {
        border-color: #8bcb2e; }
      .modal form .input-container input:nth-of-type(1) {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px; }
      .modal form .input-container input:nth-of-type(3) {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px; }
  .modal form textarea {
    height: 100px;
    width: 100%;
    border-radius: 15px;
    padding-left: 30px;
    padding-top: 20px;
    font-size: 13px;
    border: 1px solid #dbdac9;
    resize: none;
    margin-bottom: 15px; }
    .modal form textarea:focus {
      border-color: #8bcb2e; }
  .modal form .share {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer; }
    .modal form .share label:nth-of-type(1) {
      cursor: pointer; }
    .modal form .share label:nth-of-type(2) {
      cursor: pointer;
      font-size: 13px;
      color: #b8b8b7; }
    .modal form .share input {
      display: none; }
  .modal form button {
    height: 60px;
    width: 210px;
    border-radius: 100px;
    border: none;
    color: white;
    background: #8bcb2e;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    cursor: pointer;
    transition-duration: 0.4s; }
    .modal form button:hover {
      color: #8bcb2e;
      background: white; }

.modal-footer {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  background: url(../img/modalBG.png) center top no-repeat;
  padding-top: 75px;
  padding-bottom: 25px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  @media (max-width: 520px) {
    .modal-footer {
      background-size: cover; } }

.hero {
  background: url(../img/heroBG.png) center no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: -175px; }
  @media (max-width: 991px) {
    .hero {
      margin-bottom: 0; } }
  .hero .owl-carousel {
    margin: 0 auto 100px; }
    @media (max-width: 991px) {
      .hero .owl-carousel {
        margin-bottom: 0px; } }
    .hero .owl-carousel .item {
      background: url(../img/heroBG.png) center no-repeat;
      background-size: cover;
      padding-top: 290px;
      padding-bottom: 300px; }
      @media (max-width: 767px) {
        .hero .owl-carousel .item {
          padding-top: 100px;
          padding-bottom: 730px; } }
  .hero .owl-nav {
    position: absolute;
    max-width: 830px;
    left: 15px;
    right: 15px;
    top: 340px;
    margin: auto; }
    @media (max-width: 767px) {
      .hero .owl-nav {
        top: 160px; } }
    @media (max-width: 575px) {
      .hero .owl-nav {
        top: 310px; } }
    .hero .owl-nav button {
      margin: 0 !important; }
  .hero .owl-dots {
    display: none; }
  .hero-element {
    max-width: 420px;
    margin: auto; }
    @media (max-width: 767px) {
      .hero-element {
        max-width: 300px; } }
    .hero-element .section-title {
      font-size: 28px;
      color: #dbd8ac;
      font-family: 'Panton-ExtraBold';
      text-align: center;
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 5px; }
      @media (max-width: 575px) {
        .hero-element .section-title {
          font-size: 20px; } }
      .hero-element .section-title:after {
        display: block;
        width: 45px;
        height: 1px;
        background: #8bcb2e;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto; }
    .hero-element .days {
      color: #56a90e;
      font-size: 40px;
      font-family: 'Panton-ExtraBold';
      text-align: center;
      margin-bottom: 25px; }
      @media (max-width: 575px) {
        .hero-element .days {
          font-size: 25px; } }
    .hero-element .buttonDetails {
      height: 65px;
      width: 220px;
      background: #8bcb2e;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      border-radius: 100px;
      margin: auto;
      transition-duration: 0.4s; }
      .hero-element .buttonDetails:hover {
        background: black;
        color: #8bcb2e; }
  .hero .threeHouses {
    max-width: 730px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    @media (max-width: 767px) {
      .hero .threeHouses-ul {
        flex-direction: column; } }

.whyUs {
  padding: 130px 0 100px;
  background: url(../img/whyUsBG.png) no-repeat center top;
  position: relative;
  z-index: 1; }
  @media (max-width: 991px) {
    .whyUs {
      background: #54a60e;
      padding-top: 60px;
      padding-bottom: 50px; } }
  .whyUs:after {
    display: block;
    width: 153px;
    height: 53px;
    content: "";
    bottom: 30px;
    left: 0;
    right: 0;
    position: absolute;
    background: url(../img/award.png) center no-repeat;
    margin: auto; }
    @media (max-width: 991px) {
      .whyUs:after {
        bottom: -20px; } }
  .whyUs .section-title {
    font-size: 26px;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    color: black;
    margin-bottom: 30px; }
  .whyUs .elements {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .whyUs .elements {
        flex-wrap: wrap;
        justify-content: space-around; } }
  .whyUs-element {
    text-align: center;
    width: 20%;
    transition-duration: 0.4s; }
    .whyUs-element:hover {
      transform: translateY(-20px); }
    @media (max-width: 991px) {
      .whyUs-element {
        width: 33.33333333%;
        margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .whyUs-element {
        width: 50%; } }
    @media (max-width: 575px) {
      .whyUs-element {
        width: 100%; } }
    .whyUs-element img {
      margin-bottom: 15px; }
    .whyUs-element p {
      text-transform: uppercase;
      color: #ffffff;
      font-family: 'Panton-ExtraBold';
      font-size: 14px; }
    .whyUs-element span {
      color: black;
      font-size: 11px;
      font-family: 'Panton-ExtraBold';
      display: inline-block;
      position: relative; }
      .whyUs-element span:after {
        display: block;
        width: 10px;
        height: 1px;
        top: -2px;
        bottom: 0;
        margin: auto;
        left: -15px;
        content: "";
        background: black;
        position: absolute; }
      .whyUs-element span:before {
        display: block;
        width: 10px;
        height: 1px;
        top: -2px;
        bottom: 0;
        margin: auto;
        right: -15px;
        content: "";
        background: black;
        position: absolute; }

.aboutUs {
  text-align: center;
  padding-bottom: 50px; }
  @media (max-width: 991px) {
    .aboutUs {
      padding-top: 50px; } }
  .aboutUs .text1 {
    font-size: 18px;
    font-family: 'GothamPro-Bold';
    max-width: 700px;
    margin: 0 auto 40px;
    padding-bottom: 25px;
    position: relative; }
    .aboutUs .text1:after {
      display: block;
      height: 1px;
      width: 110px;
      background: black;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute; }
  .aboutUs .text2 {
    font-size: 14px;
    color: #6f6f6f;
    max-width: 820px;
    margin: 0 auto 20px; }
  .aboutUs .more {
    font-size: 12px;
    color: #8ccd2f;
    font-family: 'Panton-ExtraBold';
    text-transform: uppercase;
    border-bottom: 1px solid; }

.works {
  overflow: hidden;
  padding-top: 60px;
  background: #f9f9eb;
  position: relative; }
  @media (max-width: 767px) {
    .works {
      padding-bottom: 50px; } }
  .works:after {
    display: block;
    width: 665px;
    height: 665px;
    border-radius: 1000px;
    background: white;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -570px; }
    @media (max-width: 767px) {
      .works:after {
        display: none; } }
  .works .owl-carousel {
    margin-bottom: 100px; }
    @media (max-width: 767px) {
      .works .owl-carousel {
        margin-bottom: 50px; } }
    .works .owl-carousel .owl-item {
      opacity: 0.5;
      transition-duration: 0.4s; }
      .works .owl-carousel .owl-item.active {
        opacity: 1; }
  .works .owl-stage-outer {
    overflow: visible; }
  .works .owl-nav {
    position: absolute;
    width: 100%;
    max-width: 360px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0; }
    @media (max-width: 575px) {
      .works .owl-nav {
        position: static; } }
    .works .owl-nav button {
      margin: 0 !important;
      top: -94px; }
      @media (max-width: 575px) {
        .works .owl-nav button {
          top: auto;
          bottom: -40px; } }
  .works .owl-dots {
    display: none; }
  .works .section-title {
    font-size: 20px;
    text-align: center;
    font-family: 'Panton-ExtraBold';
    margin-bottom: 60px;
    position: relative; }
    .works .section-title span {
      position: relative;
      z-index: 5; }
    .works .section-title:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      height: 1px;
      background: #dbdac9;
      content: "";
      z-index: 0; }
    .works .section-title:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      background: #f9f9eb;
      content: "";
      width: 200px;
      z-index: 1; }
  .works .work img {
    transition-duration: 0.4s; }
  .works .work .area {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #f9f9eb;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    margin: -20px auto 0;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .works .work .area span:nth-of-type(1) {
      display: block;
      color: black;
      border-bottom: 1px solid;
      max-width: 35px; }
    .works .work .area span:nth-of-type(2) {
      display: block;
      color: #8ccd2f; }
  .works .work .name {
    color: black;
    text-align: center;
    font-family: 'Panton-ExtraBold'; }
  .works .work:hover img {
    filter: brightness(50%); }
  .works .allWorks {
    width: 225px;
    height: 65px;
    background: #c3835b;
    border-radius: 100px;
    color: white;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition-duration: 0.4s;
    z-index: 1;
    position: relative; }
    .works .allWorks:hover {
      color: #c3835b;
      background: white; }

.stocks {
  padding-top: 85px;
  padding-bottom: 60px; }
  .stocks .owl-nav {
    max-width: 300px;
    position: relative;
    margin: 60px auto 0; }
  .stocks .section-title {
    font-size: 26px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    border-bottom: 1px solid #dbdac9;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 50px; }
    .stocks .section-title:after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 1px;
      width: 100%;
      max-width: 315px;
      background: #8ccd2f;
      margin: auto; }
  .stocks .item {
    border-radius: 5px;
    height: 190px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;
    color: #403b37;
    text-transform: uppercase;
    font-family: 'Panton-Bold'; }
    .stocks .item.stock1 {
      background: url(../img/stockBG1.png) center no-repeat;
      background-size: cover; }
    .stocks .item.stock2 {
      background: url(../img/stockBG2.png) center no-repeat;
      background-size: cover; }

.whyUs2 {
  background: url(../img/whyUs2BG.png) center no-repeat;
  background-size: cover; }
  .whyUs2 .container {
    padding-top: 220px;
    padding-bottom: 220px;
    position: relative; }
    @media (max-width: 991px) {
      .whyUs2 .container {
        padding-top: 50px;
        padding-bottom: 50px; } }
  .whyUs2-center {
    width: 379px;
    height: 379px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative; }
    @media (max-width: 991px) {
      .whyUs2-center {
        margin-bottom: 20px; } }
    @media (max-width: 575px) {
      .whyUs2-center {
        width: auto;
        height: auto;
        background: none; } }
    .whyUs2-center:after {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: "";
      background: url(../img/whyUs2Check.png) center no-repeat;
      background-size: 100% 100%;
      animation: pulse 3s infinite; }
    .whyUs2-center p {
      font-family: 'Panton-ExtraBold';
      font-size: 60px;
      text-align: center;
      color: white;
      margin-bottom: 40px;
      position: relative;
      z-index: 1; }
      .whyUs2-center p span {
        display: block;
        font-size: 26px;
        line-height: 0px; }
    .whyUs2-center a {
      width: 220px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: #8ccd2f;
      color: black;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      transition-duration: 0.4s;
      position: relative;
      z-index: 1; }
      .whyUs2-center a:hover {
        background: black;
        color: #8ccd2f; }
  @media (max-width: 991px) {
    .whyUs2 .elements {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; } }
  .whyUs2-element {
    max-width: 260px;
    text-align: center;
    position: absolute;
    height: 200px; }
    @media (max-width: 991px) {
      .whyUs2-element {
        position: static;
        margin: 0 !important;
        height: auto;
        margin-bottom: 20px !important; } }
    .whyUs2-element .title {
      color: #c8e943;
      font-size: 15px;
      font-family: 'Panton-ExtraBold';
      text-transform: uppercase;
      margin-bottom: 10px; }
    .whyUs2-element .text {
      font-size: 14px;
      color: white; }
    .whyUs2-element:nth-of-type(1) {
      left: 0;
      right: 0;
      margin: auto;
      top: 10px; }
    .whyUs2-element:nth-of-type(2) {
      right: 10%;
      top: 70px; }
    .whyUs2-element:nth-of-type(3) {
      right: 1%;
      top: 0;
      bottom: 0;
      margin: auto; }
    .whyUs2-element:nth-of-type(4) {
      right: 10%;
      bottom: 70px; }
    .whyUs2-element:nth-of-type(5) {
      right: 0;
      left: 0;
      bottom: 60px;
      margin: auto; }
    .whyUs2-element:nth-of-type(6) {
      left: 10%;
      bottom: 70px; }
    .whyUs2-element:nth-of-type(7) {
      left: 1%;
      top: 0;
      bottom: 0;
      margin: auto; }
    .whyUs2-element:nth-of-type(8) {
      left: 10%;
      top: 70px; }

.feedbacks {
  padding-top: 65px;
  padding-bottom: 45px;
  position: relative; }
  .feedbacks .container {
    position: relative; }
  @media (max-width: 767px) {
    .feedbacks .owl-nav {
      display: none; } }
  .feedbacks .owl-carousel {
    max-width: 840px;
    margin: 0 auto 30px; }
  .feedbacks .section-title {
    font-size: 26px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    position: relative;
    margin-bottom: 55px; }
  .feedbacks-number {
    text-align: center;
    background: white;
    width: 65px;
    margin: 0 auto 40px;
    color: #838383;
    font-family: 'Panton-Light'; }
    .feedbacks-number:after {
      display: block;
      position: absolute;
      max-width: 440px;
      height: 1px;
      margin: auto;
      left: 0;
      right: 0;
      content: "";
      background: #8ccd2f;
      top: 97px;
      z-index: -1; }
    .feedbacks-number:before {
      display: block;
      position: absolute;
      height: 1px;
      margin: auto;
      left: 0;
      right: 0;
      content: "";
      background: #dbdac9;
      top: 97px;
      z-index: -1; }
  .feedbacks .feedback {
    margin-bottom: 30px;
    text-align: center; }
    .feedbacks .feedback-img {
      width: 100%;
      max-width: 373px;
      margin: 0 auto -14px; }
    .feedbacks .feedback-icon {
      width: 100%;
      max-width: 53px;
      margin: 0 auto 10px; }
    .feedbacks .feedback-name {
      display: inline-block;
      position: relative;
      padding-left: 70px;
      padding-right: 70px;
      font-family: 'Panton-ExtraBold';
      color: black;
      margin-bottom: 15px; }
      .feedbacks .feedback-name:after {
        display: block;
        position: absolute;
        left: 0;
        width: 50px;
        height: 1px;
        bottom: 0;
        top: 0;
        margin: auto;
        content: "";
        background: #d5d5d5; }
      .feedbacks .feedback-name:before {
        display: block;
        position: absolute;
        right: 0;
        width: 50px;
        height: 1px;
        bottom: 0;
        top: 0;
        margin: auto;
        content: "";
        background: #d5d5d5; }
    .feedbacks .feedback-type {
      font-size: 13px;
      color: #8ccd2f;
      font-family: 'GothamPro-Bold';
      margin-bottom: 10px; }
    .feedbacks .feedback-text {
      font-size: 14px;
      color: #6f6f6f; }
  .feedbacks .allFeedbacks {
    width: 220px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    font-family: 'Panton-ExtraBold';
    background: #c3835b;
    border-radius: 100px;
    margin: auto;
    transition-duration: 0.4s; }
    .feedbacks .allFeedbacks:hover {
      background: white;
      color: #c3835b; }

@keyframes pulse {
  0% {
    transform: scale(0.9); }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.9); } }

.hero2 {
  margin-bottom: 0;
  padding-top: 200px;
  padding-bottom: 40px;
  margin-bottom: -45px; }
  @media (max-width: 1199px) {
    .hero2 {
      padding-top: 100px; } }
  .hero2 .breadcrumbs {
    text-align: center;
    font-size: 12px; }
    .hero2 .breadcrumbs a {
      color: #c4cba2; }
    .hero2 .breadcrumbs i {
      color: #c4cba2;
      font-size: 10px;
      padding-left: 10px;
      padding-right: 10px; }

.mainHouse {
  background: #f9f9eb;
  padding-bottom: 40px;
  padding-top: 40px; }
  .mainHouse .houses {
    display: flex;
    position: relative;
    margin-bottom: 40px; }
    .mainHouse .houses .tab-content {
      width: 78%;
      flex-shrink: 0; }
    .mainHouse .houses .multiple-items {
      width: 15%; }
      .mainHouse .houses .multiple-items .slick-slide {
        border: none;
        width: auto !important; }
        .mainHouse .houses .multiple-items .slick-slide a {
          display: block;
          border: 5px solid #dbdbdb; }
          @media (max-width: 575px) {
            .mainHouse .houses .multiple-items .slick-slide a {
              border: 3px solid #dbdbdb; } }
          .mainHouse .houses .multiple-items .slick-slide a.active {
            border-color: #89ca32; }
          .mainHouse .houses .multiple-items .slick-slide a img {
            width: 100%;
            height: auto; }
      .mainHouse .houses .multiple-items .slick-prev {
        position: absolute;
        right: -30px;
        background: transparent;
        border: none;
        top: 0;
        z-index: 1;
        cursor: pointer; }
        @media (max-width: 575px) {
          .mainHouse .houses .multiple-items .slick-prev {
            display: none !important; } }
      .mainHouse .houses .multiple-items .slick-next {
        position: absolute;
        right: -30px;
        background: transparent;
        border: none;
        bottom: 0;
        z-index: 1;
        cursor: pointer; }
        @media (max-width: 575px) {
          .mainHouse .houses .multiple-items .slick-next {
            display: none !important; } }
      .mainHouse .houses .multiple-items .slick-dots {
        position: absolute;
        right: -23px;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .mainHouse .houses .multiple-items .slick-dots li {
          color: transparent;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          border: none;
          cursor: pointer;
          background: #d9d9cf;
          margin-top: 5px;
          margin-bottom: 5px; }
          .mainHouse .houses .multiple-items .slick-dots li button {
            display: none; }
          .mainHouse .houses .multiple-items .slick-dots li.slick-active {
            background: #8bcc2f; }
  @media (max-width: 991px) {
    .mainHouse .whyUs3 {
      padding-bottom: 30px; } }
  .mainHouse .whyUs3 .section-title {
    font-size: 26px;
    text-align: center;
    font-family: 'Panton-ExtraBold';
    margin-bottom: 40px; }
  .mainHouse .whyUs3 .elements {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .mainHouse .whyUs3 .elements {
        flex-wrap: wrap; } }
    .mainHouse .whyUs3 .elements .element {
      flex-direction: column;
      align-items: center;
      display: flex; }
      @media (max-width: 767px) {
        .mainHouse .whyUs3 .elements .element {
          width: 50%;
          margin-bottom: 20px; } }
      @media (max-width: 575px) {
        .mainHouse .whyUs3 .elements .element {
          width: 100%; } }
      .mainHouse .whyUs3 .elements .element p {
        font-size: 14px;
        font-family: 'Panton-ExtraBold'; }
      .mainHouse .whyUs3 .elements .element .number {
        font-family: 'GothamPro-Bold';
        font-size: 11px;
        color: #c3835b;
        position: relative;
        padding-left: 15px;
        padding-right: 15px; }
        .mainHouse .whyUs3 .elements .element .number:after {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 1px;
          width: 7px;
          background: black;
          content: ""; }
        .mainHouse .whyUs3 .elements .element .number:before {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          height: 1px;
          width: 7px;
          background: black;
          content: ""; }
  .mainHouse-desc {
    margin-bottom: 40px; }
    .mainHouse-desc .element {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px; }
      @media (max-width: 575px) {
        .mainHouse-desc .element {
          flex-direction: column; } }
      .mainHouse-desc .element .name {
        font-size: 14px;
        color: #7a7a70;
        flex-shrink: 0; }
      .mainHouse-desc .element .line {
        position: relative;
        top: -5px;
        border-bottom: 1px dotted #7a7a70;
        width: 100%; }
        @media (max-width: 575px) {
          .mainHouse-desc .element .line {
            display: none; } }
      .mainHouse-desc .element .value {
        width: 220px;
        font-size: 14px;
        color: black;
        font-family: 'GothamPro-Bold';
        flex-shrink: 0; }
        @media (max-width: 1199px) {
          .mainHouse-desc .element .value {
            width: 190px; } }
  .mainHouse .types {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between; }
    .mainHouse .types .element {
      background: white;
      padding: 10px;
      border: 1px solid #dbdac9;
      border-radius: 15px;
      width: 47%;
      margin-bottom: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (max-width: 575px) {
        .mainHouse .types .element {
          width: 100%; } }
      .mainHouse .types .element .text1 {
        color: #c3835b;
        font-family: 'GothamPro-Bold';
        font-size: 14px; }
      .mainHouse .types .element .text2 {
        color: #8bcb2e;
        font-family: 'GothamPro-Bold';
        font-size: 14px; }
      .mainHouse .types .element .text3 {
        color: #c3835b;
        font-size: 14px; }
      .mainHouse .types .element .price {
        padding-top: 10px; }
        .mainHouse .types .element .price .old {
          text-decoration: line-through; }
        .mainHouse .types .element .price .simple {
          font-size: 26px;
          font-family: 'GothamPro-Bold';
          line-height: 23px; }
        .mainHouse .types .element .price .new {
          font-size: 26px;
          font-family: 'GothamPro-Bold';
          color: #ff7800;
          line-height: 23px; }
        .mainHouse .types .element .price .rub {
          font-size: 14px;
          font-family: 'GothamPro-Bold'; }
  .mainHouse .buttonGreen {
    max-width: 320px;
    display: flex;
    background: #8bcb2e;
    height: 70px;
    border-radius: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: black;
    font-family: 'Panton-ExtraBold';
    margin: 0 auto 20px;
    transition-duration: 0.4s;
    padding-left: 5px;
    padding-right: 5px; }
    .mainHouse .buttonGreen:hover {
      background: black;
      color: #8bcb2e; }
  .mainHouse .greyText {
    text-align: center;
    font-size: 13px;
    color: #aaaaaa; }

.pageName {
  background: url(../img/pageNameBG.png) center top;
  background-size: cover;
  padding-top: 40px; }
  .pageName .container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px; }
  .pageName .text {
    text-align: center;
    font-size: 30px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-transform: uppercase;
    max-width: 600px;
    margin: auto;
    line-height: 38px; }
    @media (max-width: 575px) {
      .pageName .text {
        font-size: 20px;
        line-height: 25px; } }
  .pageName-nav {
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }
    @media (max-width: 991px) {
      .pageName-nav {
        margin: 0;
        display: flex;
        flex-direction: column; } }
    .pageName-nav span {
      font-size: 11px;
      color: white;
      font-family: 'GothamPro-Bold'; }
    .pageName-nav.prev {
      left: 0; }
      @media (max-width: 991px) {
        .pageName-nav.prev {
          flex-direction: column-reverse;
          top: 23px; } }
      @media (max-width: 575px) {
        .pageName-nav.prev {
          left: 5px; } }
    .pageName-nav.next {
      right: 0; }
      @media (max-width: 991px) {
        .pageName-nav.next {
          top: 10px; } }
      @media (max-width: 575px) {
        .pageName-nav.next {
          right: 5px; } }

.complectations {
  padding-top: 95px;
  border-bottom: 2px solid #dbdac9; }
  .complectations .section-title {
    font-size: 20px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    margin-bottom: 80px; }
    @media (max-width: 767px) {
      .complectations .section-title {
        margin-bottom: 40px; } }
  .complectations-nav {
    border: none;
    display: flex; }
    @media (max-width: 767px) {
      .complectations-nav {
        flex-direction: column; } }
    .complectations-nav a {
      text-align: center;
      width: 20%;
      padding-bottom: 10px; }
      @media (max-width: 767px) {
        .complectations-nav a {
          width: 100%;
          margin-bottom: 15px !important; } }
      .complectations-nav a h4 {
        font-size: 15px;
        color: #8bcb2e;
        font-family: 'Panton-ExtraBold';
        text-transform: uppercase;
        max-width: 170px;
        margin: 0 auto 10px; }
        @media (max-width: 767px) {
          .complectations-nav a h4 {
            margin: auto; } }
      .complectations-nav a p {
        font-size: 13px;
        color: #a0a0a0;
        max-width: 170px;
        margin: auto; }
        @media (max-width: 767px) {
          .complectations-nav a p {
            max-width: none; } }
      .complectations-nav a.active, .complectations-nav a:hover {
        border-bottom: 2px solid #8bcb2e; }
        @media (max-width: 767px) {
          .complectations-nav a.active, .complectations-nav a:hover {
            border: none; } }
        .complectations-nav a.active h4, .complectations-nav a:hover h4 {
          color: black; }
        .complectations-nav a.active p, .complectations-nav a:hover p {
          color: black; }

.complectations-content {
  background: #f9f9eb; }
  .complectations-content .row {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #dbdac9; }
    .complectations-content .row:last-of-type {
      border-bottom: none; }
    .complectations-content .row .name {
      font-size: 14px;
      font-family: 'GothamPro-Bold'; }
      @media (max-width: 991px) {
        .complectations-content .row .name {
          margin-bottom: 15px; } }
    @media (max-width: 991px) {
      .complectations-content .row .img-fluid {
        margin-bottom: 15px; } }
    .complectations-content .row .text {
      font-size: 14px;
      color: #aaaaaa; }

.threeHouses.section {
  background: url(../img/whyUsBG.png) center bottom no-repeat #f9f9eb;
  padding-top: 50px;
  padding-bottom: 70px; }
  @media (max-width: 767px) {
    .threeHouses.section {
      background: #8ccd2f; } }
  @media (max-width: 767px) {
    .threeHouses.section .threeHouses-ul {
      flex-direction: column; } }
  .threeHouses.section .threeHouses-li ul:after {
    background: white; }
  .threeHouses.section .threeHouses-li ul li {
    background: white; }
  .threeHouses.section .threeHouses-a span {
    color: black; }

.works2 {
  overflow: hidden;
  padding-top: 60px;
  background: #f9f9eb;
  position: relative;
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .works2 {
      padding-bottom: 50px; } }
  .works2:after {
    display: none; }
    @media (max-width: 767px) {
      .works2:after {
        display: none; } }
  .works2 .owl-carousel {
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      .works2 .owl-carousel {
        margin-bottom: 50px; } }
    .works2 .owl-carousel .owl-item {
      opacity: 0.5;
      transition-duration: 0.4s; }
      .works2 .owl-carousel .owl-item.active {
        opacity: 1; }
  .works2 .owl-stage-outer {
    overflow: visible; }
  .works2 .owl-nav {
    position: absolute;
    width: 100%;
    max-width: 400px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0; }
    @media (max-width: 575px) {
      .works2 .owl-nav {
        position: static; } }
    .works2 .owl-nav button {
      margin: 0 !important;
      top: -94px; }
      @media (max-width: 575px) {
        .works2 .owl-nav button {
          top: auto;
          bottom: -40px; } }
  .works2 .owl-dots {
    display: none; }
  .works2 .section-title {
    font-size: 20px;
    text-align: center;
    font-family: 'Panton-ExtraBold';
    margin-bottom: 60px;
    position: relative; }
    .works2 .section-title span {
      position: relative;
      z-index: 5; }
    .works2 .section-title:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      height: 1px;
      background: #dbdac9;
      content: "";
      z-index: 0; }
    .works2 .section-title:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      background: #f9f9eb;
      content: "";
      width: 280px;
      z-index: 1; }
  .works2 .work .area {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #f9f9eb;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    margin: -20px auto 0;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .works2 .work .area span:nth-of-type(1) {
      display: block;
      color: black;
      border-bottom: 1px solid;
      max-width: 35px; }
    .works2 .work .area span:nth-of-type(2) {
      display: block;
      color: #8ccd2f; }
  .works2 .work .name {
    color: black;
    text-align: center;
    font-family: 'Panton-ExtraBold'; }
  .works2 .allWorks {
    width: 225px;
    height: 65px;
    background: #c3835b;
    border-radius: 100px;
    color: white;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition-duration: 0.4s;
    z-index: 1;
    position: relative; }
    .works2 .allWorks:hover {
      color: #c3835b;
      background: white; }
