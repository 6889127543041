.hero2 {
  margin-bottom: 0;
  padding-top: 200px;
  padding-bottom: 40px;
  margin-bottom: -45px;
  @media(max-width: 1199px) {
    padding-top: 100px; }
  .breadcrumbs {
    text-align: center;
    font-size: 12px;
    a {
      color: #c4cba2; }
    i {
      color: #c4cba2;
      font-size: 10px;
      padding-left: 10px;
      padding-right: 10px; } } }
.mainHouse {
  background: #f9f9eb;
  padding-bottom: 40px;
  padding-top: 40px;
  .houses {
    display: flex;
    position: relative;
    margin-bottom: 40px;
    .tab-content {
      width: 78%;
      flex-shrink: 0; }
    .multiple-items {
      width: 15%;
      .slick-slide {
        border: none;
        width: auto !important;
        a {
          display: block;
          border: 5px solid #dbdbdb;
          @media(max-width: 575px) {
            border: 3px solid #dbdbdb; }
          &.active {
            border-color: #89ca32; }
          img {
            width: 100%;
            height: auto; }
          &.active {} } }
      .slick-prev {
        position: absolute;
        right: -30px;
        background: transparent;
        border: none;
        top: 0;
        z-index: 1;
        cursor: pointer;
        @media(max-width: 575px) {
          display: none !important; } }
      .slick-next {
        position: absolute;
        right: -30px;
        background: transparent;
        border: none;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
        @media(max-width: 575px) {
          display: none !important; } }
      .slick-dots {
        position: absolute;
        right: -23px;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        li {
          color: transparent;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          border: none;
          cursor: pointer;
          background: #d9d9cf;
          margin-top: 5px;
          margin-bottom: 5px;
          button {
            display: none; }
          &.slick-active {
            background: #8bcc2f;
            button {} } } } } }

  .whyUs3 {
    @media(max-width: 991px) {
      padding-bottom: 30px; }
    .section-title {
      font-size: 26px;
      text-align: center;
      font-family: 'Panton-ExtraBold';
      margin-bottom: 40px; }
    .elements {
      display: flex;
      justify-content: space-between;
      @media(max-width: 767px) {
        flex-wrap: wrap; }
      .element {
        flex-direction: column;
        align-items: center;
        display: flex;
        @media(max-width: 767px) {
          width: 50%;
          margin-bottom: 20px; }
        @media(max-width: 575px) {
          width: 100%; }
        img {}
        p {
          font-size: 14px;
          font-family: 'Panton-ExtraBold'; }
        .number {
          font-family: 'GothamPro-Bold';
          font-size: 11px;
          color: #c3835b;
          position: relative;
          padding-left: 15px;
          padding-right: 15px;
          &:after {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 1px;
            width: 7px;
            background: black;
            content: ""; }
          &:before {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 1px;
            width: 7px;
            background: black;
            content: ""; } } } } }
  &-desc {
    margin-bottom: 40px;
    .element {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      @media(max-width: 575px) {
        flex-direction: column; }
      .name {
        font-size: 14px;
        color: #7a7a70;
        flex-shrink: 0; }
      .line {
        position: relative;
        top: -5px;
        border-bottom: 1px dotted #7a7a70;
        width: 100%;
        @media(max-width: 575px) {
          display: none; } }
      .value {
        width: 220px;
        font-size: 14px;
        color: black;
        font-family: 'GothamPro-Bold';
        flex-shrink: 0;
        @media(max-width: 1199px) {
          width: 190px; } } } }
  .types {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    .element {
      background: white;
      padding: 10px;
      border: 1px solid #dbdac9;
      border-radius: 15px;
      width: 47%;
      margin-bottom: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media(max-width: 575px) {
        width: 100%; }
      .text1 {
        color: #c3835b;
        font-family: 'GothamPro-Bold';
        font-size: 14px; }
      .text2 {
        color: #8bcb2e;
        font-family: 'GothamPro-Bold';
        font-size: 14px; }
      .text3 {
        color: #c3835b;
        font-size: 14px; }
      .price {
        padding-top: 10px;
        .old {
          text-decoration: line-through; }
        .simple {
          font-size: 26px;
          font-family: 'GothamPro-Bold';
          line-height: 23px; }
        .new {
          font-size: 26px;
          font-family: 'GothamPro-Bold';
          color: #ff7800;
          line-height: 23px; }
        .rub {
          font-size: 14px;
          font-family: 'GothamPro-Bold'; } } } }
  .buttonGreen {
    max-width: 320px;
    display: flex;
    background: #8bcb2e;
    height: 70px;
    border-radius: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: black;
    font-family: 'Panton-ExtraBold';
    margin: 0 auto 20px;
    transition-duration: 0.4s;
    padding-left: 5px;
    padding-right: 5px;
    &:hover {
      background: black;
      color: #8bcb2e; } }
  .greyText {
    text-align: center;
    font-size: 13px;
    color: #aaaaaa; } }
.pageName {
  background: url(../img/pageNameBG.png) center top;
  background-size: cover;
  padding-top: 40px;
  .container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px; }
  .text {
    text-align: center;
    font-size: 30px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-transform: uppercase;
    max-width: 600px;
    margin: auto;
    line-height: 38px;
    @media(max-width: 575px) {
      font-size: 20px;
      line-height: 25px; } }
  &-nav {
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    @media(max-width: 991px) {
      margin: 0;
      display: flex;
      flex-direction: column; }
    span {
      font-size: 11px;
      color: white;
      font-family: 'GothamPro-Bold'; }
    img {}
    &.prev {
      left: 0;
      @media(max-width: 991px) {
        flex-direction: column-reverse;
        top: 23px; }
      @media(max-width: 575px) {
        left: 5px; } }
    &.next {
      right: 0;
      @media(max-width: 991px) {
        top: 10px; }
      @media(max-width: 575px) {
        right: 5px; } } } }
.complectations {
  padding-top: 95px;
  border-bottom: 2px solid #dbdac9;
  .section-title {
    font-size: 20px;
    color: black;
    font-family: 'Panton-ExtraBold';
    text-align: center;
    margin-bottom: 80px;
    @media(max-width: 767px) {
      margin-bottom: 40px; } }
  &-nav {
    border: none;
    display: flex;
    @media(max-width: 767px) {
      flex-direction: column; }
    a {
      text-align: center;
      width: 20%;
      padding-bottom: 10px;
      @media(max-width: 767px) {
        width: 100%;
        margin-bottom: 15px !important; }
      h4 {
        font-size: 15px;
        color: #8bcb2e;
        font-family: 'Panton-ExtraBold';
        text-transform: uppercase;
        max-width: 170px;
        margin: 0 auto 10px;
        @media(max-width: 767px) {
          margin: auto; } }
      p {
        font-size: 13px;
        color: #a0a0a0;
        max-width: 170px;
        margin: auto;
        @media(max-width: 767px) {
          max-width: none; } }
      &.active, &:hover {
        border-bottom: 2px solid #8bcb2e;
        @media(max-width: 767px) {
          border: none; }
        h4 {
          color: black; }
        p {
          color: black; } } } } }
.complectations-content {
  background: #f9f9eb;
  .row {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #dbdac9;
    &:last-of-type {
      border-bottom: none; }
    .name {
      font-size: 14px;
      font-family: 'GothamPro-Bold';
      @media(max-width: 991px) {
        margin-bottom: 15px; } }
    .img-fluid {
      @media(max-width: 991px) {
        margin-bottom: 15px; } }
    .text {
      font-size: 14px;
      color: #aaaaaa; } } }
.threeHouses.section {
  background: url(../img/whyUsBG.png) center bottom no-repeat #f9f9eb;
  padding-top: 50px;
  padding-bottom: 70px;
  @media(max-width: 767px) {
    background: #8ccd2f; }
  .threeHouses-ul {
    @media(max-width: 767px) {
      flex-direction: column; } }
  .threeHouses-li {
    ul {
      &:after {
        background: white; }
      li {
        background: white;
        a {} } } }
  .threeHouses-a {
    img {}
    span {
      color: black; } } }

.works2 {
  overflow: hidden;
  padding-top: 60px;
  background: #f9f9eb;
  position: relative;
  padding-bottom: 30px;
  @media(max-width: 767px) {
    padding-bottom: 50px; }
  &:after {
    display: none;
    @media(max-width: 767px) {
      display: none; } }
  .owl-carousel {
    margin-bottom: 50px;
    @media(max-width: 767px) {
      margin-bottom: 50px; }
    .owl-item {
      opacity: 0.5;
      transition-duration: 0.4s;
      &.active {
        opacity: 1; } } }
  .owl-stage-outer {
    overflow: visible; }
  .owl-nav {
    position: absolute;
    width: 100%;
    max-width: 400px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    @media(max-width: 575px) {
      position: static; }
    button {
      margin: 0 !important;
      top: -94px;
      @media(max-width: 575px) {
        top: auto;
        bottom: -40px; }
      &.owl-prev {}
      &.owl-next {} } }
  .owl-dots {
    display: none;
    .owl-dot {
      span {} } }
  .section-title {
    font-size: 20px;
    text-align: center;
    font-family: 'Panton-ExtraBold';
    margin-bottom: 60px;
    position: relative;
    span {
      position: relative;
      z-index: 5; }
    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      height: 1px;
      background: #dbdac9;
      content: "";
      z-index: 0; }
    &:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      background: #f9f9eb;
      content: "";
      width: 280px;
      z-index: 1; } }
  .work {
    img {}
    .area {
      width: 60px;
      height: 60px;
      border-radius: 100px;
      background: #f9f9eb;
      font-size: 12px;
      font-family: 'Panton-ExtraBold';
      text-align: center;
      margin: -20px auto 0;
      z-index: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-of-type(1) {
        display: block;
        color: black;
        border-bottom: 1px solid;
        max-width: 35px; }
      span:nth-of-type(2) {
        display: block;
        color: #8ccd2f; } }
    .name {
      color: black;
      text-align: center;
      font-family: 'Panton-ExtraBold'; } }
  .allWorks {
    width: 225px;
    height: 65px;
    background: #c3835b;
    border-radius: 100px;
    color: white;
    font-size: 12px;
    font-family: 'Panton-ExtraBold';
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition-duration: 0.4s;
    z-index: 1;
    position: relative;
    &:hover {
      color: #c3835b;
      background: white; } } }
